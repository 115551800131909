import { createStore } from 'vuex'
import { logout as apiLogout, deleteAccount, accountOptions, dissociatePlayer } from '../service/API';
import Cookie from 'js-cookie';
// Token is now into cookie


// Create a new store instance
const store = createStore({
    state: {
        logged: !!sessionStorage.getItem('user'), // Vérifier si un utilisateur est dans sessionStorage
        user: JSON.parse(sessionStorage.getItem('user')) || null,
        token: Cookie.get('aaron_token') || null,
        cmds: sessionStorage.getItem('commands') || null,
    },
    computed: {
        console: () => console,
        window: () => window,
    },
    mutations: {
        SET_USER(state, user) {
            state.user = user;
            state.logged = user !== null;
            if (user) {
                sessionStorage.setItem('user', JSON.stringify(user)); // Enregistrer l'utilisateur dans sessionStorage
            } else {
                sessionStorage.removeItem('user'); // Supprimer les données si l'utilisateur est nul
            }
        },
        LOGOUT(state) {
            state.logged = false;
            state.user = null;
            state.token = null;
            sessionStorage.removeItem('user');
        },
        SET_PLAYER(state, player) {
            if (state.user) {
                state.user.player = player;
                sessionStorage.setItem('user', JSON.stringify(state.user)); // Mettre à jour l'utilisateur dans sessionStorage
            }
        },
        SET_HDV_ALERT(state, hdvEnabled) {
            if (state.user) {
                state.user.hdvEnabled = hdvEnabled;
                sessionStorage.setItem('user', JSON.stringify(state.user)); // Mettre à jour l'utilisateur avec hdvEnabled
            }
        },
        SET_CMDS(state, cmds) {
            state.cmds = cmds;
            sessionStorage.setItem('commands', JSON.stringify(cmds));
        }
    },
    actions: {
        // Login action
        refreshUser({ commit }, user) {
            commit('SET_USER', user);
        },
        // Logout action
        logout({ commit }) {
            commit('LOGOUT');
            apiLogout(); // Appeler la fonction API de déconnexion
        },
        // Set player
        setPlayer({ commit }, player) {
            commit('SET_PLAYER', player);
        },
        dissociatePlayer({ commit }) {
            commit('SET_PLAYER', null);
            dissociatePlayer();
        },
        deleteAccount({ commit }) {
            commit('LOGOUT');
            deleteAccount();
        },
        setHdvAlert({ commit }, hdvEnabled) {
            commit('SET_HDV_ALERT', hdvEnabled);
            accountOptions({ hdvEnabled: hdvEnabled });
        }
    },
    getters: {
        logged: (state) => {
            return state.logged;
        },
        user: (state) => {
            return state.user;
        },
        commands: (state) => {
            const cmds = state.cmds;
            return JSON.parse(cmds);
        }
    }
});


export default store;
