<template>
    <n-select
        :options="channelsOptions"
        :default-value="'# ' + defaultValue"
    ></n-select>
</template>

<script>
export default {
    name: "ChannelSelect",
    props: {
        defaultValue: {
            type: String,
            required: true
        },
        guildDiscord: {
            type: Object,
            required: true,
        }
    },
    computed: {
        channelsOptions() {
            return this.guildDiscord.channels.filter(c => c.type === 0).map(c => ({
                label: "# " + c.name, 
                value: c.id,
                disabled: !c.permissions.includes("SendMessages")
            }));
        }
    }
}
</script>