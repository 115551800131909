<template>
    <h1 class="gap">
        <n-avatar :src="`https://cdn.discordapp.com/avatars/${user.discordId}/${user.avatar}.png`" round size="large"></n-avatar>
        {{ user.name }}
    </h1>
    <div class="card-container">
        <n-alert v-if="!user.player" title="Non lié à NationsGlory" type="warning">
            <div class="flex-between">
                <router-link to="auth/discord">
                    <n-button class="button" type="tertiary">Résoudre</n-button>
                </router-link>
                <span> Vous n'avez pas accès à toutes les fonctionnalités.</span>
            </div>       
        </n-alert>
        <n-card v-else>
            <n-space vertical>
                <h3>Association NationsGlory</h3>
                <div class="flex-start gap">
                    <img :src="`https://skins.nationsglory.fr/face/${user.player.pseudo}/3d/3`" round size="large"></img>
                    <n-gradient-text size="25">{{ user.player.pseudo }}</n-gradient-text >
                </div>
                <n-button type="error" @click="dissociate">Dissocier</n-button>
            </n-space>
        </n-card>
        <n-card>
            <n-space vertical>
                <ChangePassword/>
                <div>
                    <n-popconfirm 
                        :negative-text="null" 
                        positive-text="confirmer"
                        @positive-click="deleteAccount">
                    <template #trigger>
                        <n-button type="error">Supprimer le compte</n-button>
                    </template>
                    Attention, cette action est irréversible et vous perdrez toutes vos données.
                    </n-popconfirm>
                </div>
            </n-space>
        </n-card>
    </div>
</template>


<script>
import ChangePassword from "./ChangePassword.vue";
import { mapGetters } from "vuex";
export default {
    components: {
        ChangePassword,
    },
    computed: {
        ...mapGetters(['user']), // Importer le getter isConnected du store Vuex
    },
    data: () => ({
        email: "",
        password: "",
        showApi: false
    }),
    methods: {
        deleteAccount() {
            this.$store.dispatch("deleteAccount");
        },
        dissociate() {
            this.$store.dispatch("dissociatePlayer");
        }
    }
}
</script>

<style scoped>
    .card-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        gap: 15px;
    }
</style>