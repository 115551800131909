<template>
  <div class="containerSlider">
    <h2> 
      Politique de confidentialité
    </h2>
    <p>
      <b>Utilisation des cookies</b>
      Un jeton d'authentification est stocké pour vous permettre de rester connecté le temps que votre onglet reste ouvert.
    </p>
    <p>
      <b>Données collectées</b>
      Les données personelles collectées sont les suivantes :
        - Identifiant discord
        - Pseudo ingame
        - Date de création du compte
        - email
        - activation des notifications pour l'hotel des ventes
        - visibilité de l'identifiant Discord
        - les alertes vous correspondant
    </p>
    <p>
      <b>Utilisation des données</b>
      Les données renseignées par l'utilisateur sont utilisées pour l'authentification et la gestion de compte.
      
      L'identifiant discord peut être utilisé aux joueurs pour vous retrouver à partir de votre pseudo ingame.
      Le reste des informations ne sont pas partagées avec les autres utilisateurs.
    </p>
    <h2>
      Conditions générales d'utilisation et politique de confidentialité
    </h2>
    <p>
      <b>Article 1</b> - Chaque manquement aux
      <RouterLink to="/cgu">conditions générales d'utilisation</RouterLink>
      entrainent les sanctions suivantes :
    </p>
    <ol>
      <li>Suppression des données.</li>
      <li>Avertissement.</li>
      <li>Bannissement temporaire de 7 jours.</li>
      <li>Bannissement définitif.</li>
      <li>Banni du service, vous pouvez faire une demande touts les 6 mois.</li>
    </ol>
    <p>
      <b class="important">Tout contournement équivaut à un bannissement définitif de toutes
        personnes impliquées.</b>
    </p>
    <p>
      <b>Article 2</b> - Toute appropriation des services d'Aaron le mercenaire est
      interdite. Tout utilisateur s'appropriant un service, ou une partie d'un service
      d'Aaron le mercenaire, en tant qu'entité physique ou morale,
      et ne faisant pas parti de l'équipe, sera sanctionné. Son acte est considéré comme une
      violation de la propriété intellectuelle des réels contributeurs.
    </p>
    <p>
      <b>Article 3</b> - Limites d'utilisation : Toute utilisation volontaire ou non,
      visible de nos services entrainant un ralentissement de ceux-ci,
      ou à un arrêt est formellement interdite.
      En cas de constatation d'un dysfonctionnement entrainant le ralentissement, ou l'arrêt
      d'un service suite à votre utilisation, il vous est impératif d'en avertir les développeurs
      via un <b>ticket support sur notre
      <a href="https://discord.gg/HgJRH8zr8z" target="_blank" rel="noopener noreferrer">
        serveur Discord communautaire
      </a></b>.
    </p>
    <p>
      <b>Article 4</b> - Les données que nous exploitons sont des données publiques
      stockées temporairement. Elles sont destinées à être rendues publiques
      dans
      <RouterLink to="/docs">notre api</RouterLink>
      . Pour toute demande de
      déréférencement merci de vous adresser à :
      <a href="https://nationsglory.fr" target="_blank" ref="noopener noreferrer">nationsglory.fr</a>.
      Après cela votre compte sera automatiquement supprimé lors de la prochaine collecte.
    </p>
    <p>
      <b>Article 5</b> - Dans des cas exceptionnels de harcèlement, vous avez le droit
      de demander une immunité temporaire. Conformément aux lois française
      toute utilisation des commandes ayant pour but l'harcèlement moral est interdite.
      Par conséquent nous nous réservons le droit d'appliquer des sanctions envers les utilisateurs
      malveillants.
    </p>
    <p>
      <b>Article 6</b> - Toute utilisation de l'api dans le but de faire du plagiat
      est interdite.
    </p>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style scoped>
.containerSlider {
  color: var(--paragraph);
  border-radius: 15px;
  padding: 1em 5em;
  line-height: 1.4em;
  overflow-y: scroll;
  scroll-padding-bottom: auto;
}

.containerSlider h2 {
  color: var(--headline);
}

.containerSlider a {
  color: var(--link-color);
  border-bottom: 1px solid var(--link-color);
}

.containerSlider .important {
  /* color: var(--secondary); */
}

.containerSlider::-webkit-scrollbar {
  width: 14px;
  background-color: rgb(27, 27, 27);
}

/* Track */
.containerSlider::-webkit-scrollbar-track {
  border-radius: 20px;
}

/* Handle */
.containerSlider::-webkit-scrollbar-thumb {
  background: rgb(22, 22, 22);
  border-radius: 10px;
}

/* Handle on hover */
.containerSlider::-webkit-scrollbar-thumb:hover {
  background: #0f0f0f;
}
</style>
