<template>
    <n-tabs
      key="target-selector"
      type="card"
      animated
      placement="left"
    >
        <n-tab-pane
          name="add"
          tab="+ Ajouter"
        >
            <ServerSelect :default-value="newTarget.color" v-model="newTarget.color" :guildDiscord="guildDiscord"  @update:value="v => newTarget.color = v"/>
            <n-form-item label="Nom de la cible">
                <n-input placeholder="Nom" @update:value="v => newTarget.name = v"/>
            </n-form-item>
            <n-button :disabled="!newTarget?.color || newTarget?.name?.length < 3" type="primary" @click="addTarget">Ajouter</n-button>
        </n-tab-pane>
        <n-tab-pane
            
          v-for="target in targets"
          :tab="`${getName(target)}`"
          :name="target._id"
        >
            <div :style="getStyleOfHeader(target)" class="target-header">            
                <n-space>
                    <n-icon size="30" style="margin-right: 5px; top: 5px;">
                        <ServerIcon :server="target.color"/>
                    </n-icon>
                    <p class="item-title">{{ getName(target) }}</p>
                </n-space>
                <n-text depth="3">Expire le {{ new Date(target.expired*1000).toLocaleString() }}</n-text>
            </div>
            <n-form-item label="Salon d'envoi personnalisé">
                <ChannelSelectRaw :guildDiscord="guildDiscord" :defaultValue="target.channel ?? '<Aucun salon>'" @update:value="v => target.channelId = v"/>
            </n-form-item>
            <n-form-item label="@Mention">
                <RoleSelectRaw :guildDiscord="guildDiscord" :defaultValue="target.role" @update:value="v => target.tag = v"/>
            </n-form-item>
            <n-form-item item label="Message">
                <n-input type="textarea" :rows="4" @update:value="v => target.message = v"/>
            </n-form-item>
            <n-form-item item label="Message">
                <n-input type="textarea" :rows="4" @update:value="v => target.message = v"/>
            </n-form-item>
            <n-space v-if="type === 'hdv'">
                <n-form-item label="Prix minimum">
                    <n-input-number
                        :default-value="target.target.min ?? 0"
                        @update:value="v => target.target.min = v"
                    >
                    <template #suffix>$</template>
                    </n-input-number>
                </n-form-item>
                <n-form-item label="Prix maximum">
                    <n-input-number
                        :default-value="target.target.max"
                        placeholder="Illimité"
                        @update:value="v => target.target.max = v"
                    >
                    <template #suffix>$</template>
                    </n-input-number>
                </n-form-item>
            </n-space>
            <n-space>
                <n-button type="primary" @click="() => updateTarget(target)">Enregistrer</n-button>
                <n-button type="error" @click="() => deleteTarget(target)">Supprimer</n-button>
            </n-space>
        </n-tab-pane>
    </n-tabs>
</template>

<script>
import { useMessage } from 'naive-ui';
import {API} from "@/service/API";
import {ServerIcon} from "@/assets/icons/index.js";
import ChannelSelectRaw from './Guild/ChannelSelectRaw.vue';
import RoleSelectRaw from './Guild/RoleSelectRaw.vue';
import ServerSelect from '../ServerSelect.vue';

export default {
    name: 'TargetSelector',
    components: {
        ServerIcon, ChannelSelectRaw, RoleSelectRaw, ServerSelect
    },
    props: {
        type: {
            type: String,
            required: true
        },
        triggerType: {
            type: String,
            required: true
        },
        triggerId: {
            type: String,
            required: true
        },
        guildDiscord: {
            type: Object,
            required: true
        },
        guildDB: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            message: useMessage(),
            targets: [],
            newTarget: {
                name: "",
                color: this.guildDB.baseServer.color
            }
        };
    },
    mounted() {
        this.updateList();
    },
    methods: {
        updateList() {
            API.get(`/target?triggerType=${this.triggerType}&trigger=${this.triggerId}&type=${this.type}`).then(res => {
                this.targets = res.data;
            });
        },
        getName(target) {
            switch (this.type) {
                case "countries":
                case "wars":
                    return target.target?.caseName;
                case "spy":
                    return target.target?.player.pseudo;
                case "hdv":
                    return target.target.itemName;
                default:
                    return "Non défini";
            }
        },
        getLowerName(target) {
            switch (this.type) {
                case "countries":
                case "wars":
                    return target.target.name;
                case "spy":
                    return target.target.player.pseudo;
                case "hdv":
                    return target.target.itemName;
                default:
                    return "Non défini";
            }
        },
        updateTarget(target) {
            API.put(`/target/add`, {
                target: this.getLowerName(target),
                channelId: target.channelId,
                tag: target.tag,
                message: target.message,
                triggerType: this.triggerType,
                type: this.type,
                color: target.color,
                trigger: this.triggerId,
                min: target.target.min,
                max: target.target.max
            }).then(() => {
                this.message.success("La cible a été mise à jour");
            }).catch(() => {
                this.message.error("Une erreur est survenue");
            });
        },
        getStyleOfHeader(target) {
            switch(this.type) {
                case "countries":
                case "wars":
                    return`background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('data:image/png;base64,${target.target.flag}');`;
                case "spy":
                    return `background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url('https://skins.nationsglory.fr/face/${this.getName(target)}/3d/15');`;
                case "hdv":
                    return '';
                default:
                    return '';
            }
        }, 
        addTarget() {
            API.put(`/target/add`, {
                target: this.type === "spy" ? this.newTarget.name : this.newTarget.name.toLowerCase(),
                triggerType: this.triggerType,
                type: this.type,
                color: this.newTarget.color,
                trigger: this.triggerId,
            }).then(() => {
                this.message.success("La cible a été ajoutée");
                this.updateList();
            }).catch((err) => {
                const statusCode = err.response.status;
                switch(statusCode) {
                    case 404:
                        switch(this.type) {
                            case "countries":
                            case "wars":
                                this.message.error(`Le pays ${this.newTarget.name} est inexistant`);
                                break;
                            case "spy":
                                this.message.error(`Le joueur ${this.newTarget.name} n'existe pas`);
                                break;
                            default:
                                this.message.error("Une erreur est survenue");
                        }
                        break;
                    default:
                        this.message.error("Une erreur est survenue");
                }
            });
        },
        deleteTarget(target) {
            API.put(`/target/delete`, {
                target: this.getLowerName(target),
                triggerType: this.triggerType,
                type: this.type,
                trigger: this.triggerId,
                color: target.color
            }).then(() => {
                this.message.success("La cible a été supprimée");
                this.updateList();
            }).catch(() => {
                this.message.error("Une erreur est survenue");
            });
        }
    },
};
</script>

<style scoped>
.item-title {
    margin: 3px;
    font-size: 1.5em;
    font-weight: bold;
}
.target-header {
    background-size: cover;
    background-position: center;
    padding: 10px;
}
</style>