<template>
  <header>
    <div id="root">
      <div class="flex-start">
        <div class="topnav flex-between">
          <div class="align flex-start">
            <img
                id="logonav"
                src="../assets/apple-touch-icon.webp"
                alt="logo"
                @click="$router.push({ name: 'home' })"
            />
            <router-link id="home_link" to="/">Aaron</router-link>
            <!-- Menu classique -->
            <nav id="topnav_menu">
              <router-link to="/">Accueil</router-link>

              <router-link to="/commandes">Commandes</router-link>

              <a class="topnav_link"
                href="/docs"
                target="_blank"
                referrerpolicy="no-referrer">
                Documentation API
              </a>

              <a
                  class="topnav_link"
                  href="https://discord.gg/4BXMDh56xf"
                  target="_blank"
                  rel="noopener noreferrer"
              >Support/Discord</a>
            </nav>
          </div>
          <div v-if="!logged" class="flex">
            <n-button type="primary" style="margin-top: 15px;margin-right: 15px;">
              <router-link class="gap" to="/login">
              <n-icon>
                <DiscordIcon/>
              </n-icon>
              Se connecter
            </router-link>
            </n-button> 
          </div>
          <div v-else class="flex hiddenNav">
          <n-button type="error" style="margin-top: 15px;margin-right: 15px;" @click="logout();$router.push('/')">
              <n-icon>
                <LogOutOutline/>
              </n-icon>
              Déconnexion
            </n-button> 
            <router-link to="/profile">
                <n-button type="primary" style="margin-top: 15px;margin-right: 15px;">
                  <n-icon>
                    <SettingsOutline/>
                  </n-icon>
                  Configurer
                </n-button>
              </router-link>
        </div>

      </div>
      <a
            id="topnav_hamburger_icon"
            href="#"
            @click="showResponsiveMenu()"
        >
          <span></span>
          <span></span>
          <span></span>
        </a>
        <!-- Menu Responsive  -->
        <nav role="navigation" id="topnav_responsive_menu">
          <ul>
            <li>
              <router-link to="/">🏠 Accueil</router-link>
            </li>
            <li>
              <router-link to="/commandes">🤖 Commandes</router-link>
            </li>
            <li>
              <a
                  class="topnav_link"
                  href="https://discord.gg/4BXMDh56xf"
                  target="_blank"
                  referrerpolicy="no-referrer"
              >📞 Support/Discord</a
              >
            </li>
            <li>
              <a class="topnav_link"
                  href="/docs"
                  target="_blank"
                  referrerpolicy="no-referrer">Documentation API</a>
            </li>
            <li v-if="logged">
              <n-button type="error" style="margin-top: 15px;margin-right: 15px;" @click="logout()">
                <n-icon>
                  <LogOutOutline/>
                </n-icon>
                Déconnexion
              </n-button>
            </li>
            <li v-if="logged">    
              <router-link to="/profile">
                <n-button type="primary" style="margin-top: 15px;margin-right: 15px;">
                  <n-icon>
                    <SettingsOutline/>
                  </n-icon>
                  Configurer
                </n-button>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </header>
</template>

<script>
import { DiscordIcon, LogOutOutline, SettingsOutline} from "@/assets/icons";
import { mapGetters, mapActions} from "vuex"
import { h } from 'vue';
export default {
  name: "home",
  components: {
    DiscordIcon,
    LogOutOutline,
    SettingsOutline
  },
  computed: {
      ...mapGetters(['logged', 'user']), // Importer le getter isConnected du store Vuex
  },
  methods: {
    ...mapActions(["logout"]),
    showResponsiveMenu() {
      const menu = document.getElementById("topnav_responsive_menu");
      const icon = document.getElementById("topnav_hamburger_icon");
      const root = document.getElementById("root");
      if (menu.className === "") {
        menu.className = "open";
        icon.className = "open";
        root.style.overflowY = "hidden";
      } else {
        menu.className = "";
        icon.className = "";
        root.style.overflowY = "";
      }
    },
  },
};
</script>
<style scoped>
/*MENU*/


.topnav {
  width: 100%;
}

#topnav_menu {
  padding: 20px;
  margin-left: 1.5em;
}

#topnav_menu * {
  padding: 12px;
}

#topnav_menu a {
  font-size: 18px;
  font-weight: 600;

  color: var(--paragraph);
  position: relative;

  padding: 12px;
  transition: color 250ms ease-in-out;
}

#topnav_menu a::before {
  content: "";
  background-color: var(--hover-link-color);
  position: absolute;
  left: 50%;
  bottom: 11px;
  width: 0;
  height: 8px;
  z-index: -1;
  transition: all 300ms ease-in-out;

  transform: translateX(-50%);
}

#topnav_menu a:hover {
  color: var(--headline);
}

#topnav_menu a:hover::before {
  width: 95%;
}

nav:hover {
  cursor: pointer;
}

/*masquer menu hamburger*/


#home_link {
  font-family: atmos, serif;
  padding: 5px;
  color: white;
  text-decoration: none;

  font-size: 1.2em;
}

/*ajuster logo*/
#logonav {
  border-radius: 50%;
  width: 50px;
  height: 50px;

  padding: 20px;
  margin-left: 5px;

  cursor: pointer;
}

/*masquer menu hamburger*/
#topnav_hamburger_icon,
#topnav_responsive_menu {
  display: none;
}

#home_link {
  font-family: atmos, serif;
}

@media only screen and (max-width: 900px) {
  /* masquer menu classique */
  #topnav_menu, .hiddenNav {
    display: none;
  }

  /* masquer menu classique */
  #home_link {
    flex-grow: 1;
    font-family: atmos, serif;
  }

  /* desactiver le scrool horizontal et vertical */
  #root {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  /* Afficher le menu réactif à droite de l'écran */
  #topnav_responsive_menu {
    display: block;
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99;
    transform-origin: 0 0;
    transform: translate(200%, 0);
    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  }

  #topnav_responsive_menu ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin: 0;
    right: 0;
    top: 0;
    min-width: 50vw;
    height: 100vh;
    padding: 56px 0 0;
    text-align: center;
    background: #ededed;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
  }

  #topnav_responsive_menu li {
    padding: 12px 24px;
  }

  #topnav_responsive_menu li * {
    white-space: nowrap;
    color: #333;
    text-decoration: none;
  }

  #topnav_responsive_menu.open {
    transform: none;
    position: fixed;
  }

  .hamburger_link {
  }

  /* ******************** HAMBURGER ICON ******************** */
  /* définit la taille et la position du lien hamburger */
  #topnav_hamburger_icon {
    display: block;
    position: relative;
    margin: 16px;
    width: 33px;
    height: 28px;
    z-index: 100;
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
  }

  #topnav_hamburger_icon span {
    display: block;
    position: absolute;
    height: 4px;
    width: 100%;
    margin-bottom: 5px;
    background: #ededed;
    border-radius: 3px;
    z-index: 100;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.25s ease-in-out;
    -moz-transition: 0.25s ease-in-out;
    -o-transition: 0.25s ease-in-out;
    transition: 0.25s ease-in-out;
  }

  #topnav_hamburger_icon span:nth-child(1) {
    top: 0px;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left top;
  }

  #topnav_hamburger_icon span:nth-child(2) {
    top: 12px;
    -webkit-transform-origin: left center;
    -moz-transform-origin: left center;
    -o-transform-origin: left center;
    transform-origin: left center;
  }

  #topnav_hamburger_icon span:nth-child(3) {
    top: 24px;
    -webkit-transform-origin: left bottom;
    -moz-transform-origin: left bottom;
    -o-transform-origin: left bottom;
    transform-origin: left bottom;
  }

  /* changer couleur logo hamburger à l'ouverture du menu */
  #topnav_hamburger_icon.open span {
    background: #333;
  }

  /* 1er spam rotation 45° \ */
  #topnav_hamburger_icon.open span:nth-child(1) {
    width: 110%;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  #topnav_hamburger_icon.open span:nth-child(2) {
    width: 0;
    opacity: 0;
  }

  /* rotation dernier spam -45° / */
  #topnav_hamburger_icon.open span:nth-child(3) {
    width: 110%;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}
</style>
