<template>
  <div class="pane" style="max-height: 60vh;min-height: 350px;">  
  <h1 class="main-title">Aaron Le Mercenaire</h1>
  <p class="description">
    Un bot certifié par NationsGlory, reliant NG à discord ! <br />
    Trouve tes pillages, disbands, et pleins d'autres fonctionnalités !
  </p>

  <div class="buttons-container"> 
    <router-link to="/invite" target="_blank">
       <n-button class="button" type="primary">
        <div class="gap">
          <n-icon size="25">
            <DiscordIcon />
          </n-icon>
          Ajouter à Discord
        </div>
      </n-button>  
  </router-link>
    <router-link to="/commandes">
      <n-button class="button gap" secondary type="default">
        <div class="gap">
          <n-icon size="25">
            <CodeSlash />    
          </n-icon>
          Voir les fonctionnalités
        </div>
      </n-button>
    </router-link>
  </div>
</div>
<n-divider />
<div class="pane splited">
  <div class="pane-img">
    <img src="https://i.imgur.com/2JHzywp.pngf">
  </div>
  <div class="pane-content">
    <n-card>
      <h3>GAGNEZ DU TEMPS</h3>
      <n-grid cols="1 s:2" gap="30px" item-responsive responsive="screen">
        <n-grid-item>
          <div><n-avatar size="large" round style="background:darkblue">
            <n-icon color="black">
              <EarthSharp />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="info">LOCALISER</n-gradient-text>
        </div>
          <p>Vous pouvez localiser des coordonnées avec <code>/coords</code> de bases, sur la lune, mars...</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:var(--btn-color)">
            <n-icon color="black">
              <EyeOutline />
            </n-icon>
          </n-avatar>
          <n-gradient-text>ANTI-ANTI-JEU</n-gradient-text>
          </div>
          <p>Avec <code>/reseau</code> connecter des joueurs chez un ennemi et l'assaut n'as jamais été aussi facile.</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:darkred">
            <n-icon color="black">
              <BonfireOutline />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="error">PILLAGES ET DISBANDS</n-gradient-text>
          </div>
          <p>Avec <code>/reseau</code> connectez des joueurs chez un ennemi et l'assaut n'as jamais été aussi facile.</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:green">
            <n-icon color="black">
              <ShieldHalf />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="success">MODÉRATION</n-gradient-text>
          </div>
          <p>Obtenir la liste des claims dissociés, pays sans f home et le montant de l'amende</p>
        </n-grid-item>
      </n-grid>
    </n-card>
  </div>
</div>
<div class="splited">
    <iframe class="iframeYtb" style="margin: auto;" width="560" height="315" src="https://www.youtube.com/embed/3n6TBKUSURE?si=oBJG5PQhU1oE8PzP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
<div class="pane splited">
  <div class="pane-content">
    <n-card>
      <h3>... ET DES OPPORTUNITÉES</h3>
      <n-grid cols="1 s:2" gap="30px" item-responsive responsive="screen">
        <n-grid-item>
          <div><n-avatar size="large" round style="background:darkblue">
            <n-icon color="black">
              <Flag />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="info">GESTION DE PAYS</n-gradient-text>
        </div>
          <p>Vous notifie des changement de relations, joueurs qui quittent et rejoignent, nouvelles notations et des risques de pillage et disbands</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:var(--btn-color)">
            <n-icon color="black">
              <LogInOutline />
            </n-icon>
          </n-avatar>
          <n-gradient-text>ESPIONNAGE</n-gradient-text>
          </div>
          <p>Aaron vous notifie lors de la connection d'un joueur. Peut servir a des fins de modération, joueur indésirable, commerce...</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:darkred">
            <n-icon color="black">
              <BonfireOutline />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="error">GUERRES</n-gradient-text>
          </div>
          <p>Vous préviens dès qu'il y a possibilité d'assaut.</p>
        </n-grid-item>
        <n-grid-item>
          <div>
          <n-avatar size="large" round style="background:green">
            <n-icon color="black">
              <CartOutline />
            </n-icon>
          </n-avatar>
          <n-gradient-text type="success">COMMERCE</n-gradient-text>
          </div>
          <p>Vous aide à maximiser les gains à l'hotel des ventes, préviens en cas de stock vide ou d'item expiré.</p>
        </n-grid-item>
      </n-grid>
    </n-card>
  </div>
  <div class="pane-img">
    <img src="https://i.imgur.com/Vq0uCmI.png">
  </div>
</div>
<div class="pane flex">
  <n-divider />
  <n-card>
    <n-page-header subtitle="Le bot des bons plans">
    <n-grid :cols="5">
      <n-gi>
        <n-statistic label="Serveurs" value="3,001" />
      </n-gi>
      <n-gi>
        <n-statistic label="Commandes" value="+218k" />
      </n-gi>
      <n-gi>
        <n-statistic label="Pays" value="~2,030" />
      </n-gi>
      <n-gi>
        <n-statistic label="Utilisateurs potentiels" value="92,974" />
      </n-gi>
      <n-gi>
        <n-statistic label="Joueurs enregistrés" value="215,217" />
      </n-gi>
    </n-grid>
    <template #title>
        Aaron Le Mercenaire
    </template>
    <template #header>
    </template>
    <template #avatar>
      <img
                id="logonav"
                src="../assets/apple-touch-icon.webp"
                alt="logo"
                style="width: 50px; height: 50px; border-radius:100%"
            />

    </template>
    <template #extra>
    </template>
  </n-page-header>
  <n-divider />
  
  <n-gradient-text class="flex" size="33" style="text-align: center;">Les Mercenaires à votre service</n-gradient-text>
  <div class="flex-center" style="justify-content: space-around">
    <div v-for="mercenaire in mercenaires" :key="mercenaire.ngName">
      <div>
        <img :src="`https://skins.nationsglory.fr/face/${mercenaire.ngName}/3d/9`" style="object-fit: cover;" />
      </div>
      <n-gradient-text type="warning">{{ mercenaire.role }}</n-gradient-text>
      <h3><a v-if="mercenaire.link":href="mercenaire.link" style="text-decoration: underline">{{ mercenaire.discordName }}</a><span v-else>{{ mercenaire.discordName }}</span></h3>
    </div>
  </div>
  </n-card>
</div>
</template>

<style scoped>
.pane {
  min-height: 60vh;
}
.splited {
  display: flex;
  align-items: center;
  gap: 20px;
}
.pane-content h3 {
  font-size: 30px;
}
.n-grid div div {
  gap: 10px;
  display: flex;
  font-size: 20px;
}
.pane-img img {
  width: 400px;
  object-fit: cover;
  /* upper and turn right (un peu) */
}
.main-title {
  padding: 0 15vw;
  margin-top: 0;

  text-align: center;
  font-size: 70px;

  word-break: break-word;
}

.description {
  text-align: center;
  font-size: 20px;
  word-break: break-word;

  line-height: 1.5em;
}


.button {
  height: max-content;
  margin: 10px;
  padding: .5em 1.3em;
  border-radius: 5px;

  font-size: 1.5em;
}
.buttons-container {
  display: flex;
  justify-content: center;
  gap: 1em;
}

@media only screen and (max-width: 600px) {
  .iframeYtb {
    width: 100%;
    height: 315px;
  }
}
@media only screen and (max-width: 1400px) {
  .main-title {
    font-size: 50px;
  }
  .description {
    font-size: 15px;
  }
  .button-container button {
    max-width: 100px;
  }
  #responsive_button2 {
    margin-top: 80px;
  }
  .pane.splited {
    display: block;
  }
  .pane-img img {
    width: 100%;
  } 
}

@media only screen and (max-width: 1100px) {
  .button {
    padding: 5px;
    border-radius: 5px;

    font-size: 1.2em;
  }
}
@media only screen and (max-width: 500px) {
  .main-title {
    font-size: 30px;
  }
  .description {
    font-size: 15px;
  }
  .buttons-container {
    display: flex;
    flex-direction: column;
  }
}
</style>

<script>
import {DiscordIcon, LogoDiscord, CodeSlash, EarthSharp, EyeOutline, BonfireOutline, ShieldHalf, Flag, LogInOutline, CartOutline} from '@/assets/icons/index.js';
//use components for template
export default {
  name: "App",
  components: {
    CodeSlash, DiscordIcon, LogoDiscord, EarthSharp, EyeOutline, BonfireOutline, ShieldHalf, Flag, LogInOutline, CartOutline
  },
  data() {
    return {
      mercenaires: [{
        ngName: "BGUINCHEFR",
        role: "Développement & Maintenance",
        discordName: "BigChef",
      }, {
        ngName: "Crocs",
        role: "Administrateur & Support Discord",
        discordName: "Croux",
      }, {
        name: "Pioupia",
        discordName: "Pioupia",
        role: "Développeur web",
        link: "https://pioupia.github.io/"
      }]
    };
  }
};
</script>