module.exports = {
    servers: function (key) {
        switch (key) {
            case "blue": return { r: 0, g: 119, b: 221 };
            case "orange": return { r: 238, g: 136, b: 0 };
            case "white": return { r: 190, g: 190, b: 190 };
            case "cyan": return { r: 0, g: 187, b: 221 };
            case "lime": return { r: 0, g: 204, b: 51 };
            case "coral": return { r: 255, g: 85, b: 85 };
            case "pink": return { r: 221, g: 0, b: 255 };
            case "purple": return { r: 175, g: 0, b: 255 };
            case "red": return { r: 238, g: 51, b: 0 };
            case "alpha": return { r: 238, g: 51, b: 0 };
            case "black": return { r: 23, g: 23, b: 23 };
            case "sigma": return { r: 23, g: 23, b: 23 };
            case "green": return { r: 0, g: 153, b: 51 };
            case "yellow": return { r: 138, g: 187, b: 0 };
            case "delta": return { r: 138, g: 187, b: 0 };
            case "omega": return { r: 86, g: 86, b: 86 };
            case "ruby": return { r: 148, g: 30, b: 50 };
            default: return null;
        }
    },
    grade: function (key) {
        if (!key) return;
        switch (key) {
            case "Player": return;
            case "Joueur": return;
            case "Premium": return { r: 218, g: 140, b: 0 };
            case "Legende": return { r: 0, g: 139, b: 139 };
            case "Adjudant": return { r: 0, g: 20, b: 200 };
            case "Heros": return { r: 130, g: 130, b: 130 };

            case "Fondateur": return { r: 0, g: 255, b: 255 };
            case "Founder": return { r: 0, g: 255, b: 255 }; //US

            case "CoFonda": return { r: 0, g: 255, b: 255 };
            case "Co-Fonda": return { r: 0, g: 255, b: 255 }; //Bedrock
            case "Co-Founder": return { r: 0, g: 255, b: 255 }; //US
            case "RespAdmin": return { r: 100, g: 0, b: 0 };
            case "AdminManager": return { r: 100, g: 0, b: 0 }; //US
            case "RespCom": return { r: 0, g: 115, b: 175 };
            case "Admin": return { r: 190, g: 0, b: 0 };

            case "SuperModo": return { r: 0, g: 30, b: 207 };
            case "Supermodo": return { r: 0, g: 30, b: 207 }; //Bedrock
            case "Supermod": return { r: 0, g: 30, b: 207 }; //US


            case "Moderateur_plus": return { r: 0, g: 95, b: 0 };
            case "Moderateur_Plus": return { r: 0, g: 95, b: 0 };; //Bedrock
            case "Mod_plus": return { r: 0, g: 95, b: 0 };; //US

            case "Moderateur": return { r: 0, g: 181, b: 0 };
            case "Mod": return { r: 0, g: 181, b: 0 }; //US

            case "Moderateur_Test": return { r: 0, g: 230, b: 0 };
            case "Mod_Test": return { r: 0, g: 230, b: 0 }; //US

            case "Guide": return { r: 127, g: 0, b: 150 };
            case "guide": return { r: 127, g: 0, b: 150 }; //Dissidants java/bedrock fr

            default: return { r: 0, g: 127, b: 150 }
        }
    }
}