<template>
    <div class="flex-start gap">
            <img :src="`https://skins.nationsglory.fr/face/${user.player.pseudo}/3d/4`" round size="large"></img>
            <n-gradient-text size="30">{{ user.player.pseudo }}</n-gradient-text >
    </div>
    <n-card>
        <n-space vertical>
            <h3>Alertes HDV</h3>
            <n-space>
                <n-switch
                    :rubber-band="false"
                    :value="user.hdvEnabled"
                    @update:value="save"
                />
                <span>Activer les alertes HDV</span>
            </n-space>
            <n-alert type="info" title="Vous envoie une notification si">
                <ul>
                    <li>Vos items sont vendus</li>
                    <li>Vos items sont expirés</li>
                </ul>
            </n-alert>
        </n-space>
    </n-card>
</template>


<script>
import { mapGetters } from "vuex";
import store from "@/store";

export default {
    computed: {
        ...mapGetters(['user'])
    },
    methods: {
        async save() {
            store.dispatch("setHdvAlert", !this.user.hdvEnabled);
        }
    },
    data: () => ({
        email: "",
        password: "",
        loadingHdv: false,
        showApi: false
    })
}
</script>