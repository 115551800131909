<template>
  <div class="commands_container">
    <h2>Commandes</h2>
    <div class="container">
      <n-alert v-if="error" title="Erreur lors du chargement des commandes" type="error"/>
      <n-spin v-if="loading"/>
      <Cmd :prefix="'/'" :cmds="commands" />
    </div>
  </div>
</template>

<script>
//import component
import Cmd from "../components/Cmd";
import { API } from "@/service/API";
//use components for template
export default {
  components: { Cmd },
  data: () => {
    return {
      commands: [
        {
          name: "",
          description: "Chargement des commandes...",
          options: [],
        },
      ],
      choices: {},
      loading: true,
      subCmds: {},
      error: false
    };
  },
  async mounted() {
    API({
      method: "get",
      url: "/cmds"
    })
    .then(response => {
      this.commands = response.data;
    }).catch((e) => {
      console.error(e);
      this.error = true;
      this.commands = [];
    }).finally(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.commands_container {
  width: 60%;
  margin: auto;
}

.commands_container h2 {
  font-size: 3em;
  color: white;
  text-align: center;
}

.container {
  padding: 20px;
}


@media only screen and (max-width: 1100px) {
  .commands_container {
    width: 90%;
  }
}
</style>