<template>
    <div v-if="guildDB && guildDiscord">
        <n-space vertical>
            <ServerSelect :default-value="guildDB.baseServer?.color" v-model="server" @update:modelValue="setBaseServer"/>
              <ChannelSelect :guildDB="guildDB" :guildDiscord="guildDiscord" category="config" label="Salon des annonces du bot"></ChannelSelect>
              <n-space>
                <n-switch
                    :rubber-band="false"
                    :value="guildDB.autoWar"
                    @update:value="setAutoWar"
                /><span>Ajout automatique des guerres</span>
                </n-space>
            </n-space>
    </div>
</template>

<script>
import {API} from "@/service/API";
import { useMessage } from 'naive-ui'
import ChannelSelect from "./ChannelSelect.vue";
import ServerSelect from "../../ServerSelect.vue";
import {ServerIcon} from "@/assets/icons/index.js";

export default {
    name: "General",
    components: {
        ChannelSelect, ServerIcon, ServerSelect
    },
    props: {
        guildDB: {
            type: Object,
            required: true
        },
        guildDiscord: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            message: useMessage()
        };
    },
    methods: {
        setAutoWar(value) {
            API.put("/discord/guild/autoWar", {guildId: this.guildDB.guild, autoWar: value}).then(() => {
                if(value) this.message.success("L'ajout de guerres est géré automatiquement");
                else this.message.success("L'ajout de guerres est géré manuellement");
                this.guildDB.autoWar = value;
            }).catch(err => {
                this.message.error("Une erreur est survenue");
            })
        },
        setBaseServer(value) {
            API.put("/discord/guild/baseServer", {guildId: this.guildDB.guild, baseServer: value}).then(() => {
                this.message.success("Le serveur associé a été mis à jour");
                this.guildDB.baseServer.color = value;
            }).catch(err => {
                this.message.error("Une erreur est survenue");
            });
        },
    }
}
</script>