<template>
    <ProgressSSO :current="currentStep" :currentProgress="progress"/>
    <div class="section">
      <n-alert v-if="progress === 'error'" title="Association avec NationsGlory" type="error">
        <div>
          <span>Une erreur est survenue lors de l'authentification. Voulliez ressayer</span>
        </div>
      </n-alert>
      <div v-else-if="currentStep === 2">
        <n-spin size="large"/>
      </div>
      <n-card v-else-if="currentStep === 3" class="identified">
        <img :src="`https://skins.nationsglory.fr/face/${player.pseudo}/3d/5`">
        <h3>
          <n-gradient-text size="20">{{player.pseudo}} est connecté à Aaron Le Mercenaire</n-gradient-text>
        </h3>
        <router-link to="/profile">
          <n-button type="primary">Continuer</n-button>
        </router-link>
      </n-card>
    </div>
  </template>
  <script>
  import { API } from "@/service/API";
  import { mapActions, mapGetters } from "vuex";
  import ProgressSSO from "@/components/ProgressSSO.vue";    

  export default {
  components: { ProgressSSO },
  data() {
    return {
      error: "",
      player: null,
      progress: "process",
      currentStep: 2,
    };
  },
  methods: {
    ...mapActions(["setPlayer"]),
    loginWithNg() {
      router.push("/nationsglory/login")
    },
  },
  computed: {
    ...mapGetters(["logged", "user"]),
  },
  mounted() {
    API.post("/oauth/nationsglory", {
      code: this.$route.query.access_token,
    })
      .then((response) => {
        this.currentStep = 3;
        const data = response.data;
        this.player = data.player;
        this.setPlayer(data.player);
      })
      .catch((error) => {
        this.progress = "error";
        this.error = error.response?.data?.error || "Une erreur est survenue";
      });
      this.$router.push({ 
        path: this.$route.path
      });
  }  
};
  </script>

  <style scoped>
  .section {
    max-width: fit-content;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    margin-top: 50px;
  }
  </style>