<template>
  <footer class="footer">
    <div>
      <h2>
        <RouterLink to="/cgu" class="footer__link">
          Aaron le mercenaire - Conditions générales d'utilisation
        </RouterLink>
      </h2>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  background-color: var(--footer-color);
  color: var(--headline);
  text-align: center;
  margin-top: 5em;
  padding: 20px;
}
a:visited {
  color: var(--headline);
}
.footer__developers {
  color: var(--headline);
  text-decoration: none;
  font-size: 20px;
}

</style>
