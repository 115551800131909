<template>
  <n-steps :vertical="isVertical" :current="current" :status="currentStatus">
    <n-step
      title="Connection à discord"
      description="Liez votre compte Discord à Aaron le mercenaire"
    />
    <n-step
      title="Connection à NationsGlory"
      description="Liez votre compte NationsGlory à Aaron le mercenaire"
    />
    <n-step
      title="Configuration achevée"
      description="Vous pouvez configurer votre serveur, et profiter de toutes les fonctionnalités"
    />
  </n-steps>
</template>

<script>
export default {
  props: {
    currentStatus: {
      type: String,
      default: "process",
    },
    current: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      isVertical: false, // Par défaut, pas de mode vertical
    };
  },
  mounted() {
    window.addEventListener("resize", this.checkScreenSize);
  },
  mounted() {
    // Ajoute un listener pour surveiller la taille de la fenêtre
    window.addEventListener("resize", this.checkScreenSize);
    // Vérifie la taille initiale de l'écran lors du montage du composant
    this.checkScreenSize();
  },
  beforeDestroy() {
    // Retire le listener pour éviter les fuites de mémoire
    window.removeEventListener("resize", this.checkScreenSize);
  },
  methods: {
    checkScreenSize() {
      // Définit le mode vertical si la largeur de l'écran est inférieure à 768px
      this.isVertical = window.innerWidth < 768;
    },
  },
};
</script>