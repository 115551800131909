import { darkTheme } from 'naive-ui'
export default {
  common: {
    ...darkTheme.common,
    cardColor: "#1a1a1b",
    textColorBase: '#fffffe',
    textColor1: "#fffffe",
    textColor2: "#fffffe",
    textColor3: "#aaaaaa",
    primaryColor: '#ff8906',
    primaryColorHover: '#ed8005',
    primaryColorPressed: '#d97d04',
    errorColor: '#D03A52FF',
    errorColorHover: '#D03A50F0',
    inputColor: 'rgba(255, 255, 255, 0.1)',
    inputColorDisabled: 'rgba(255, 255, 255, 0.06)',
    dividerColor: 'rgba(255, 255, 255, 0.09)',
  },
  Button: {
    textColor: 'rgba(255, 255, 255, 0.95)',
    textColorTertiary: 'rgba(255, 255, 255, 0.95)',
    textColorPrimary: 'rgba(255, 255, 255, 0.95)',
    textColorHoverPrimary: 'rgba(255, 255, 255, 0.97)',
    textColorPressedPrimary: 'rgba(255, 255, 255, 1)',
    textColorFocusPrimary: 'rgba(255, 255, 255, 0.95)',
    textColorError: 'rgba(255, 255, 255, 0.95)',
    textColorHoverError: 'rgba(255, 255, 255, 0.97)',
    textColorPressedError: 'rgba(255, 255, 255, 1)',
    textColorFocusError: 'rgba(255, 255, 255, 0.95)',
    textColorSuccess: 'rgba(255, 255, 255, 0.95)',
    textColorHoverSuccess: 'rgba(255, 255, 255, 0.97)',
    textColorPressedSuccess: 'rgba(255, 255, 255, 1)',
    textColorFocusSuccess: 'rgba(255, 255, 255, 0.95)',
    textColorWarning: 'rgba(255, 255, 255, 0.95)',
    textColorHoverWarning: 'rgba(255, 255, 255, 0.97)',
    textColorPressedWarning: 'rgba(255, 255, 255, 1)',
    textColorFocusWarning: 'rgba(255, 255, 255, 0.95)',

    colorSecondary: 'rgba(255, 255, 255, 0.10)',
    colorSecondaryHover: 'rgba(255, 255, 255, 0.15)',
    colorSecondaryPressed: 'rgba(255, 255, 255, 0.20)',

    colorSuccess: 'rgba(0, 158, 116, 0.35)',
    colorSuccessHover: 'rgba(0, 158, 116, 0.45)',
    colorSuccessPressed: 'rgba(0, 158, 116, 0.55)',
    colorSuccessFocus: 'rgba(0, 158, 116, 0.25)',

  },
  Alert: {
    /* Error */
    colorError: 'rgba(208, 58, 82, 0.25)',
    IconColorError: 'rgba(208, 58, 82)',
    borderError: '1px rgba(208, 58, 82, 0.35) solid',

    /* Warning */
    colorWarning: 'rgba(240, 138, 0, 0.25)',
    iconColorWarning: 'rgba(240, 138, 0)',
    borderWarning: '1px rgba(240, 138, 0, 0.35) solid',

    /* Info */
    colorInfo: 'rgba(0, 122, 255, 0.25)',
    iconColorInfo: 'rgba(0, 122, 255)',
    borderInfo: '1px rgba(0, 122, 255, 0.35) solid',

    /* Success */
    colorSuccess: 'rgba(0, 158, 116, 0.25)',
    iconColorSuccess: 'rgba(0, 158, 116)',
    borderSuccess: '1px rgba(0, 158, 116, 0.35) solid',
  },
  Menu: {
    itemColorHover: 'rgba(255, 255, 255, 0.15)',
  },
  Card: {
    borderColor: 'rgba(255, 255, 255, 0.09)',
  },
  Input: {
    border: '1px solid #0000',
    borderDisabled: '1px solid #0000',
  },
  Avatar: {
    color: 'rgba(255, 255, 255, 0.9)',
  },
  Typography: {
    textColorPrimary: '#ff8906',
    textColorSuccess: '#1C6739FF'
  },
}