<template>
    <n-form>
        <ChannelSelect :guildDB="guildDB" :guildDiscord="guildDiscord" category="faction" label="Salon des alertes liés au Pays"></ChannelSelect>
        <ChannelSelect :guildDB="guildDB" :guildDiscord="guildDiscord" category="notations" label="Salon des alertes liés aux notations"></ChannelSelect>
        <n-tabs default-value="countries" size="large" justify-content="space-evenly">
            <n-tab-pane name="countries" tab="🎌 Mes pays">
                <TargetSelector :triggerId="guildDB.guild" triggerType="Guild" type="countries" :guildDiscord="guildDiscord" :guildDB="guildDB"/>
            </n-tab-pane>
            <n-tab-pane name="wars" tab="⚔️ Guerres">
                <TargetSelector :triggerId="guildDB.guild" triggerType="Guild" type="wars" :guildDiscord="guildDiscord" :guildDB="guildDB"/>
            </n-tab-pane>
        </n-tabs>
    </n-form>
</template>

<script>
import TargetSelector from "../TargetSelector.vue";
import ChannelSelect from "./ChannelSelect.vue";
export default {
    name: "GuildCountry",
    components: {
        ChannelSelect, TargetSelector
    },
    props: {
        guildDB: {
            type: Object,
            required: true
        },
        guildDiscord: {
            type: Object,
            required: true,
        }
    },
    mounted() {
    },
    computed: {
    }
}
</script>