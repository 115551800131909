<template>
  <div :class="'popup ' + `color-${color}`">
    <svg v-if="icon === 'admin'"
         xmlns="http://www.w3.org/2000/svg"
         height="48" width="48">
      <path
          d="M34.55 34.5q1.3 0 2.2-.95.9-.95.9-2.25t-.9-2.2q-.9-.9-2.2-.9-1.3 0-2.25.9t-.95 2.2q0 1.3.95 2.25t2.25.95Zm-.05 6.25q1.65 0 3-.7t2.3-2q-1.3-.7-2.6-1.05-1.3-.35-2.7-.35-1.4 0-2.725.35-1.325.35-2.575 1.05.95 1.3 2.275 2t3.025.7ZM24 44q-6.9-1.6-11.45-7.825Q8 29.95 8 21.9V9.95l16-6 16 6v13.5q-.7-.35-1.5-.625T37 22.45v-10.4l-13-4.8-13 4.8v9.85q0 3.8 1.225 7t3.125 5.625q1.9 2.425 4.2 4.025 2.3 1.6 4.45 2.3.3.6.9 1.35.6.75 1 1.15-.45.25-.95.375-.5.125-.95.275Zm10.65 0q-3.9 0-6.65-2.775-2.75-2.775-2.75-6.575 0-3.9 2.75-6.675t6.65-2.775q3.85 0 6.625 2.775t2.775 6.675q0 3.8-2.775 6.575Q38.5 44 34.65 44ZM24 24.05Z"
          fill="currentColor" />
    </svg>
    <svg v-else xmlns="http://www.w3.org/2000/svg"
         height="48" width="48">
      <path
          d="M7.15 40q-1.25 0-2.2-.925Q4 38.15 4 37V11q0-1.15.9-2.075Q5.8 8 7 8h34q1.15 0 2.075.925Q44 9.85 44 11v10.5h-3V14L24 24.85 7 14v23h26.5v3ZM24 21.65 41 11H7ZM7 37V11v26Zm31.25 3q-.7 0-1.225-.525-.525-.525-.525-1.225v-6q0-.7.575-1.225.575-.525 1.425-.525v-2.25q0-1.55 1.1-2.65 1.1-1.1 2.65-1.1 1.55 0 2.65 1.1 1.1 1.1 1.1 2.65v2.25q.85 0 1.425.525.575.525.575 1.225v6q0 .7-.525 1.225Q46.95 40 46.25 40ZM40 30.5h4.5v-2.25q0-.95-.65-1.6-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.6Z"
          fill="currentColor" />
    </svg>
    <span class="popup__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: "Popup",
  props: {
    text: String,
    icon: {
      type: String,
      validator(value) {
        return ['admin', 'mp'].includes(value)
      }
    },
    color: {
      type: String,
      validator(value) {
        return ['red', 'green'].includes(value)
      }
    }
  }
}
</script>

<style scoped>
.popup {
  position: relative;
  display: inline-block;
}

.popup .popup__text {
  pointer-events: none;

  visibility: hidden;
  width: 12rem;
  text-align: center;

  position: absolute;
  z-index: 1;
  top: 50%;
  transform: translateY(-50%);

  background-color: var(--footer-color);

  margin-left: .5rem;
  padding: .25rem .5rem;
  border-radius: .2rem;
}

.popup .popup__text::after {
  --triangle-width: 8px;

  pointer-events: none;

  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: calc(-1 * var(--triangle-width));
  margin-left: calc(-2 * var(--triangle-width));
  border-width: var(--triangle-width);
  border-style: solid;
  border-color: transparent var(--footer-color)  transparent transparent;
}

.popup:hover .popup__text {
  visibility: visible;

  -webkit-animation: fadeIn 300ms;
  animation: fadeIn 300ms;
}

.popup.color-red svg {
  color: var(--secondary);
}

.popup.color-green svg {
  color: var(--green-color);
}


@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>