<template>
    <h3>Identifiants API</h3>
    <n-form ref="formRef" :model="model" :rules="rules">
      <n-form-item path="email" label="E-mail">
        <n-input v-model:value="user.email" :disabled="true" @keydown.enter.prevent />
      </n-form-item>
      <n-form-item path="password" label="Nouveau Mot de passe">
        <n-input
          v-model:value="model.password"
          type="password"
          @input="handlePasswordInput"
          @keydown.enter.prevent
        />
      </n-form-item>
      <n-form-item
        ref="rPasswordFormItemRef"
        first
        path="reenteredPassword"
        label="Nouveau Mot de passe (confirmation)"
      >
        <n-input
          v-model:value="model.reenteredPassword"
          :disabled="!model.password"
          type="password"
          @keydown.enter.prevent
        />
      </n-form-item>
      <n-row :gutter="[0, 24]">
        <n-col :span="20">
          <n-alert v-if="clipped" type="info" title="Copié dans le presse papier"/>  
          <n-spin v-if="status === 'loading'" size="small" />
          <n-alert v-else-if="status === 'error'" type="error" title="">Une erreur est survenue</n-alert>
          <n-alert v-else-if="status === 'success'" type="success">Mot de passe changé avec succès</n-alert>
        </n-col>
        <n-col :span="24">
          <div style="display: flex; justify-content: flex-end" class="gap">
            <n-button 
            round 
            type="info"
            @click="generatePassword">Générer</n-button>
            <n-button
              :disabled="model.password === null"
              round
              type="primary"
              @click="handleValidateButtonClick"
            >
              Valider
            </n-button>
          </div>
        </n-col>
      </n-row>
    </n-form>
  </template>
  
  <script>
  import { defineComponent, ref } from "vue";
  import { mapGetters } from "vuex";
  import { API } from '@/service/API';
  
  export default defineComponent({
    computed: {
      ...mapGetters(['user']),
    },
    setup() {
      const formRef = ref(null);
      const rPasswordFormItemRef = ref(null);
      const model = ref({
        email: null,
        password: null,
        reenteredPassword: null
      });
      const status = ref(""); // Use ref for reactivity
      const clipped = ref(false);
      function validatePasswordStartWith(rule, value) {
        return !!model.value.password && model.value.password.startsWith(value) && model.value.password.length >= value.length;
      }
      function validatePasswordSame(rule, value) {
        return value === model.value.password;
      }
      function entropyValidator(rule, value) {
        return value.length >= 16;
      }
      

      const rules = {
        email: [],
        password: [
          {
            required: true,
            message: "Mot de passe requis",
          },
          {
            validator: entropyValidator,
            message: "Le mot de passe doit contenir au moins 16 caractères",
          }
        ],
        reenteredPassword: [
          {
            required: true,
            message: "Re-entrer le mot de passe est requis",
            trigger: ["input", "blur"]
          },
          {
            validator: validatePasswordStartWith,
            message: "Les mots de passe ne sont pas identiques !",
            trigger: "input"
          },
          {
            validator: validatePasswordSame,
            message: "Les mots de passe ne sont pas identiques !",
            trigger: ["blur", "password-input"]
          }
        ]
      };
  
      function handlePasswordInput() {
        if (model.value.reenteredPassword) {
          rPasswordFormItemRef.value?.validate({ trigger: "password-input" });
        }
      }
      function generatePassword() {
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><f,./-=";
        let password = "";
        // Generate a number between 16 and 32
        const length = Math.floor(Math.random() * 16) + 16;
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            password += charset[randomIndex];
        }
        // 16 characters password
        model.value.password = password;
        model.value.reenteredPassword = password;
        navigator.clipboard.writeText(password);
        clipped.value = true;
        
        rPasswordFormItemRef.value?.validate();

        setTimeout(() => {
          clipped.value = false;
        }, 5000);
      }
      async function handleValidateButtonClick(e) {
        e.preventDefault();
        formRef.value?.validate((errors) => {
          if (!errors) {
            status.value = "loading";
            API.put("/password", {
              password: model.value.password
            }).then(() => {
              resetForm(); // Reset form manually
              status.value = "success";
            }).catch(() => {
              status.value = "error";
            }).finally(() => {
              setTimeout(() => {
                status.value = "";
              }, 5000);
            });
          }
        });
      }
  
      function resetForm() {
        // Manually reset the model values
        model.value.password = null;
        model.value.reenteredPassword = null;
        rPasswordFormItemRef.value?.validate(); // Revalidate form after reset
      }
  
      return {
        formRef,
        rPasswordFormItemRef,
        model,
        rules,
        status,
        clipped,
        handlePasswordInput,
        handleValidateButtonClick,
        generatePassword,
        resetForm // Expose the reset function
      };
    }
  });
  </script>
  