<template>
    <div v-if="logged">
        <div class="flex gap">
            <img v-if="icon?.length > 0" :src="`https://cdn.discordapp.com/icons/${id}/${icon}?size=32.png`"
                :style="{ width: '64px', height: '64px', borderRadius: '100%' }"
            />
            <h1> {{ name }}</h1>
        </div>
        <n-alert v-if="error === 404" title="Guilde inconnue" type="info">
                    <div>
                        <p>Cette guilde n'est pas marquée comme configurée.</p>
                        <p>1. Ajoutez le bot à votre serveur Discord</p>
                        <div class="flex-between">
                          <router-link to="/invite"><n-button type="primary">Inviter</n-button></router-link>
                          <n-button type="info" @click="refresh">Rafraichir</n-button>
                        </div>
                    </div>    
                </n-alert>
        <n-alert v-else-if="error === 500 || error != null" title="Erreur serveur" type="error">
                    <div>
                        <p>Une erreur est survenue lors de la récupération des données. Ressayez plus tard</p>
                        <n-button type="info" @click="refresh">Rafraichir</n-button>
                    </div>    
                </n-alert>
        <n-alert v-else-if="!guildDB"
          title="Guilde non enregistrée" type="warning">
            <p>Vous autorisez Aaron à enregistrer les données néssésaires à son fonctionnement.</p>
            <n-button type="info" @click="register">Enregistrer</n-button>
        </n-alert>
        <n-alert v-else-if="!permissions || error === 403"
              title="Vous n'êtes pas administrateur de la guilde" type="warning">
                <p>Vous devez être administrateur de la guilde pour accéder à cette page.</p>
                <n-button type="info" @click="refresh">Rafraichir</n-button>
        </n-alert>
        <n-card v-else style="min-width: 500px">
          <n-spin :show="loading">
            <n-tabs type="line" animated @update:value="handleUpdateTab">
              <n-tab-pane name="main" tab="Général">
                <GuildGeneral v-if="!loading" :guildDB="guildDB" :guildDiscord="guildDiscord" />
              </n-tab-pane>
              <n-tab-pane name="countries" tab="Pays">
                <GuildCountry v-if="!loading" :guildDB="guildDB" :guildDiscord="guildDiscord" />
              </n-tab-pane>
              <n-tab-pane name="spy" tab="Espionnage">
                <ChannelSelect :guildDB="guildDB" :guildDiscord="guildDiscord" category="spy" label="Salon des alertes liés à l'espionnage"></ChannelSelect>
                <TargetSelector :triggerId="guildDB.guild" triggerType="Guild" type="spy" :guildDiscord="guildDiscord" :guildDB="guildDB"/>
              </n-tab-pane>
              <n-tab-pane name="hdv" tab="Hdv">
                <TargetSelector :triggerId="guildDB.guild" triggerType="Guild" type="hdv" :guildDiscord="guildDiscord" :guildDB="guildDB"/>
              </n-tab-pane>
              <n-tab-pane name="logs" tab="Logs" disabled>
              
              </n-tab-pane>
            </n-tabs>
          </n-spin> 
        </n-card>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Add } from "@/assets/icons";
import {API} from "@/service/API";
import GuildGeneral from "./Guild/General.vue";
import GuildCountry from "./Guild/Country.vue";
import TargetSelector from "./TargetSelector.vue";
import ChannelSelect from "./Guild/ChannelSelect.vue";

export default {
  name: "App",
  components: {
    Add, GuildGeneral, GuildCountry, TargetSelector, ChannelSelect
  },
  computed: {
    ...mapGetters(['user', 'logged']),
  },
  watch: {
    id: {
      handler(guildId) {

        if(!guildId) return;
        this.loading = true;
        API.get(`/discord/guild?guildId=${guildId}`).then(res => {
          this.saveGuild(res.data)
        }).catch(err => {
          this.loading = false;
          this.error = err.response.status;
          if(err.response.status === 404) {
            this.guildDB = null;
            this.permissions = null;
            this.guildDiscord = null;
            return;
          }
          else if(err.response.status === 403) {
            this.permissions = null;
            return;
          }
        });
      },
      immediate: true // This ensures the watcher runs immediately when the component is created
    },
  },
  props: {
    id: {
      type: String,
      required: true
    },
    tab: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      guildDB: null,
      persmissions: null,
      guildDiscord: null,
      channelsOptions: [],
      servers: [],
      error:null
    };
  },
  methods: {
    saveGuild(data) {
      this.guildDB = data.dbGuild;
      this.permissions = data.permissions;
      this.guildDiscord = data.guildDiscord;
      this.error = null;
      this.loading = false;
    },
    register() {
      API.post("/discord/guild/register", {guildId: this.id}).then(res => {
        this.saveGuild(res.data);
      }); 
    },
    refresh() {
      this.loading = true;
      API.get(`/discord/guild?guildId=${this.id}`).then(res => {
        this.saveGuild(res.data)
      });
    },
    handleUpdateTab(tab) {
      this.$router.push(`/profile/guilds/${this.id}/${tab}`);
    }
  }
};
</script>

<style scoped>
    .countriesAdd {
        /** Text center vertical */
        display: flex;
        align-items: center;
    }
</style>