<template>
    <n-form-item :label="label">
    <ChannelSelectRaw
        :guildDiscord="guildDiscord"
        :defaultValue="channelsOptions.find(c => c.value === guildDB.channels[category])?.label"
        @update:value="v => updateChannel(v, category)"
    />
</n-form-item>
</template>

<script>
import {API} from "@/service/API";
import ChannelSelectRaw from "./ChannelSelectRaw.vue";
import { useMessage } from 'naive-ui'

export default {
    name: "ChannelSelect",
    components: {
        ChannelSelectRaw
    },
    props: {
        guildDB: {
            type: Object,
            required: true
        },
        category: {
            type: String,
            required: true
        },
        guildDiscord: {
            type: Object,
            required: true,
        },
        label: {
            type: String,
            required: true
        }
    },
    computed: {
        channelsOptions() {
            return this.guildDiscord.channels.filter(c => c.type === 0).map(c => ({
                label: c.name, 
                value: c.id,
                disabled: !c.permissions.includes("SendMessages")
            }));
        }
    },
    data: () => ({
        message: useMessage()
    }),
    methods: {
        updateChannel(value, category) {
            API.put("/discord/guild/channel", {guildId: this.guildDB.guild, category, channelId: value}).then(() => {
                this.message.success("Le salon a été mis à jour");
                this.guildDB.channels[category] = value;
            }).catch(err => {
                this.message.error("Une erreur est survenue");
            });
        },
    }
}
</script>