<template>
    <component v-html="servers.get(server)" />
</template>

<script>
    export default {
        props: {
            server: {
                type: String,
                required: true
            }
        },
        data: () => ({
            servers: new Map([
                ["yellow", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#EEBB00"/>
                    <path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <path d="M0 0H40V40H0V0Z" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`
                ],
                ["alpha", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="#EE3300"/>
                    <path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="white"/>
                    </svg>`],
                ["black", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#171717"/>
                    <path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <path d="M0 0H40V40H0V0Z" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>`],
                ["blue", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99998 11.1712C1.99998 9.61301 2.86399 8.18991 4.22886 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22886 32.5C2.86399 31.8101 1.99998 30.387 1.99998 28.8288V11.1712Z" fill="#0077DD"/>
                    <path d="M20.8855 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12168 11.0162C5.76385 11.7025 6.68875 13.7417 7.65959 15.8821C7.89529 16.4017 8.13369 16.9363 8.34279 17.45C8.75461 18.4617 10.8744 19.3139 13.037 20.1925C15.2145 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9701 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8855 4.95404Z" fill="white"/>
                    </g>
                    <defs>
                    <clipPath id="clip0">
                    <path d="M0 0H40V40H0V0Z" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                `],
                ["cyan", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.99998 11.1712C1.99998 9.61301 2.86399 8.18991 4.22886 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22886 32.5C2.86399 31.8101 1.99998 30.387 1.99998 28.8288V11.1712Z" fill="#00BBDD"/>
<path d="M20.8855 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12168 11.0162C5.76385 11.7025 6.68875 13.7417 7.65959 15.8821C7.89529 16.4017 8.13369 16.9363 8.34279 17.45C8.75461 18.4617 10.8744 19.3139 13.037 20.1925C15.2145 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9701 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8855 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`],
["gamma", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="#009933"/>
<path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="white"/>
</svg>
`],
["green", `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<g clip-path="url(#clip1)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.20001 6.70269C1.20001 5.7678 1.71841 4.91395 2.53734 4.5L10.9373 0.253943C11.6072 -0.0846474 12.3928 -0.0846479 13.0627 0.253943L21.4627 4.49999C22.2816 4.91395 22.8 5.7678 22.8 6.70269V17.2973C22.8 18.2322 22.2816 19.0861 21.4627 19.5L13.0627 23.7461C12.3928 24.0847 11.6072 24.0847 10.9373 23.7461L2.53734 19.5C1.71842 19.0861 1.20001 18.2322 1.20001 17.2973V6.70269Z" fill="#009933"/>
<path d="M12.5314 2.97242C12.1964 2.80313 11.8036 2.80313 11.4687 2.97242L4.27303 6.6097C3.45833 7.02151 4.01327 8.245 4.59578 9.52925C4.73719 9.84103 4.88023 10.1618 5.0057 10.47C5.25279 11.077 6.52465 11.5883 7.8222 12.1155C9.12875 12.6463 10.3187 12.9119 10.8 13.8425C11.1284 14.4773 11.1025 14.848 11.0737 15.2615C11.0281 15.9151 11.0737 16.4018 11.4 16.9133C11.4996 17.0694 11.6651 17.2456 11.8276 17.4243C12.1895 17.8224 12.4882 18.4324 12.3618 18.9607C12.1153 19.9916 11.4715 21.5086 13.1821 20.6986L13.8726 20.3496C15.1582 19.6998 16.5562 16.683 17.6212 15.7003C17.88 15.4614 18.127 15.2093 18.3208 14.943C18.5303 14.6552 18.5252 14.2569 18.2937 13.9872C17.1151 12.6142 15.581 13.1001 14.0985 13.2608C13.2035 13.3577 12.419 13.4427 12 13.2283C11.7821 13.1168 11.5573 13.0132 11.3374 12.9119C10.3466 12.4552 9.45449 12.0441 9.7307 11.1555C10.0201 10.2244 11.8149 9.38921 12.3021 10.7607C12.5593 11.4845 13.5878 12.1755 13.698 11.4468C13.7354 11.1998 13.6529 10.8913 13.5618 10.5509C13.3842 9.88727 13.1741 9.10231 13.7568 8.41459C14.1913 7.90181 14.6453 7.63141 15.1263 7.34484C15.6214 7.04995 16.1451 6.73794 16.7061 6.12709C16.8151 6.00839 17.3334 5.39978 16.7061 5.08266L12.5314 2.97242Z" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1">
<path d="M0 0H24V24H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`],
["lime", `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<g clip-path="url(#clip1)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.20001 6.70269C1.20001 5.7678 1.71841 4.91395 2.53734 4.5L10.9373 0.253943C11.6072 -0.0846474 12.3928 -0.0846479 13.0627 0.253943L21.4627 4.49999C22.2816 4.91395 22.8 5.7678 22.8 6.70269V17.2973C22.8 18.2322 22.2816 19.0861 21.4627 19.5L13.0627 23.7461C12.3928 24.0847 11.6072 24.0847 10.9373 23.7461L2.53734 19.5C1.71842 19.0861 1.20001 18.2322 1.20001 17.2973V6.70269Z" fill="#00CC33"/>
<path d="M12.5314 2.97242C12.1964 2.80313 11.8036 2.80313 11.4687 2.97242L4.27303 6.6097C3.45833 7.02151 4.01327 8.245 4.59578 9.52925C4.73719 9.84103 4.88023 10.1618 5.0057 10.47C5.25279 11.077 6.52465 11.5883 7.8222 12.1155C9.12875 12.6463 10.3187 12.9119 10.8 13.8425C11.1284 14.4773 11.1025 14.848 11.0737 15.2615C11.0281 15.9151 11.0737 16.4018 11.4 16.9133C11.4996 17.0694 11.6651 17.2456 11.8276 17.4243C12.1895 17.8224 12.4882 18.4324 12.3618 18.9607C12.1153 19.9916 11.4715 21.5086 13.1821 20.6986L13.8726 20.3496C15.1582 19.6998 16.5562 16.683 17.6212 15.7003C17.88 15.4614 18.127 15.2093 18.3208 14.943C18.5303 14.6552 18.5252 14.2569 18.2937 13.9872C17.1151 12.6142 15.581 13.1001 14.0985 13.2608C13.2035 13.3577 12.419 13.4427 12 13.2283C11.7821 13.1168 11.5573 13.0132 11.3374 12.9119C10.3466 12.4552 9.45449 12.0441 9.7307 11.1555C10.0201 10.2244 11.8149 9.38921 12.3021 10.7607C12.5593 11.4845 13.5878 12.1755 13.698 11.4468C13.7354 11.1998 13.6529 10.8913 13.5618 10.5509C13.3842 9.88727 13.1741 9.10231 13.7568 8.41459C14.1913 7.90181 14.6453 7.63141 15.1263 7.34484C15.6214 7.04995 16.1451 6.73794 16.7061 6.12709C16.8151 6.00839 17.3334 5.39978 16.7061 5.08266L12.5314 2.97242Z" fill="white"/>
</g>
</g>
<defs>
<clipPath id="clip0">
<rect width="24" height="24" fill="white"/>
</clipPath>
<clipPath id="clip1">
<path d="M0 0H24V24H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`],
["omega", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="#565656"/>
<path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="white"/>
</svg>
`], ["orange", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#EE8800"/>
<path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`], ["pink", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.99998 11.1712C1.99998 9.61301 2.86399 8.18991 4.22886 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22886 32.5C2.86399 31.8101 1.99998 30.387 1.99998 28.8288V11.1712Z" fill="#DD00FF"/>
<path d="M20.8855 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12168 11.0162C5.76385 11.7025 6.68875 13.7417 7.65959 15.8821C7.89529 16.4017 8.13369 16.9363 8.34279 17.45C8.75461 18.4617 10.8744 19.3139 13.037 20.1925C15.2145 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9701 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8855 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`], ["purple", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#AA00FF"/>
<path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="40" height="40" fill="white"/>
</clipPath>
</defs>
</svg>
`], ["red", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#EE3300"/>
<path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`], ["sigma", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="black"/>
<path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="white"/>
</svg>
`], ["white", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="#BEBEBE"/>
<path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="white"/>
</clipPath>
</defs>
</svg>
`], ["coral", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M2 11.1712C2 9.61301 2.864 8.18991 4.22888 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288V11.1712Z" fill="rgba(255, 127, 80, 1)"/>
<path d="M20.8856 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12169 11.0162C5.76386 11.7025 6.68877 13.7417 7.65961 15.8821C7.8953 16.4017 8.1337 16.9363 8.34281 17.45C8.75463 18.4617 10.8744 19.3139 13.037 20.1925C15.2146 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4562 25.4358C18.3802 26.5252 18.4562 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9702 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9683 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8856 4.95404Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0">
<path d="M0 0H40V40H0V0Z" fill="rgba(255, 127, 80, 1)"/>
</clipPath>
</defs>
</svg>
`], ["delta", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="yellow"/>
<path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="black"/>
</svg>
`], ["ruby", `<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 28.8288V5C2 3.34 2.56887 2 4.22887 2H35C36.66 2 38 3.34 38 5V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22888 32.5C2.86401 31.8101 2 30.387 2 28.8288Z" fill="rgba(139, 0, 0, 0.5)"/>
<path d="M20.5035 17.9345C20.9321 19.1409 22.6463 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1522 22.6029 17.5848C22.3069 16.4788 21.9567 15.1705 22.928 14.0243C23.6521 13.1697 24.4087 12.719 25.2105 12.2414C26.0355 11.7499 26.9085 11.2299 27.8434 10.2118C27.8434 10.2118 29 9.31251 29 7.50001C29 6.50002 28.2812 6.70001 26 6.70001H8.3428C6.34954 6.70001 6.43977 8.00002 6.43995 12.18C6.44 13.34 7.26761 15.018 7.65956 15.8821C7.89526 16.4017 8.13366 16.9363 8.34276 17.45C8.75028 18.4511 10.8303 19.296 12.9692 20.1649L13.0369 20.1925C15.2145 21.0772 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5041 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4418 28.7426 19.7126 29.0404C20.3158 29.7041 20.8135 30.7208 20.603 31.6012C20.192 33.3193 19.1191 35.8477 21.9701 34.4978L23.1209 33.916C25.2636 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8752 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013L23.4974 22.1013C22.0058 22.2629 20.6982 22.4046 20 22.0472C19.6367 21.8613 19.2621 21.6887 18.8957 21.5198C17.2442 20.7587 15.7574 20.0735 16.2178 18.5926C16.7002 17.0407 19.6914 15.6487 20.5035 17.9345Z" fill="white"/>
</svg>
`]
            ])
        }),
    }
</script>