<template>
    <n-form-item label="Serveur associé">
        <n-icon size="30" style="margin-right: 5px;">
            <ServerIcon :server="defaultValue"/>
        </n-icon>
        <n-select
            width="100%"
            :options="servers"
            :default-value="defaultValue"
            :render-tag="renderSingleSelectTag"
            @update:value="$emit('update:modelValue', $event)"
        />
    </n-form-item>
</template>

<script>
import {API} from "@/service/API";
import { useMessage } from 'naive-ui'
import {ServerIcon} from "@/assets/icons/index.js";

export default {
    name: "ServerSelect",
    components: {
        ServerIcon
    },
    props: {
        defaultValue: {
            type: String,
            default: ""
        }
    },
    mounted() {
      API.get("/serverColor").then(res => res.data.map(s => s.color)).then(colors => {
        this.servers = colors.map(c => ({label: c.charAt(0).toUpperCase() + c.slice(1), value: c}));
      });
    },
    data() {
        return {
            servers: [],
            message: useMessage()
        };
    },
    methods: {
    }
}
</script>