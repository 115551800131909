<template>
  <ProgressSSO :current="step" :currentStatus="progress" />
  <div class="section">
    <n-alert title="Une erreur est survenue" type="error" v-if="error">
      <div>Une erreur est survenue lors de l'authentification. Voulliez ressayez</div>
      <router-link to="/login">
        <n-button type="primary">Se connecter</n-button>
      </router-link>
    </n-alert>
    
    <div v-else-if="step === 2">
      <h2>Connectez vous à NationsGlory</h2>
      <div class="buttons-container">
        <n-button class="button is-link" @click="loginWithNg" type="primary">Se connecter avec NationsGlory</n-button>
        <n-button class="button is-dark" @click="goToProfile" type="secondary">Continuer sans NationsGlory</n-button>
      </div>
    </div>
    <div v-else>
      <n-spin size="large" />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"; 
import { getUser } from "@/service/API";
import ProgressSSO from "@/components/ProgressSSO.vue";

export default {
  components: { ProgressSSO },
  data: () => {
    return {
      error: "",
      progress: "process",
      isLoading: true,
      player: null,
      step: 1
    };
  },
  methods: {
    ...mapActions(["refreshUser"]),
    loginWithNg() {
      this.$router.push("/nationsglory/login")
    },
    goToProfile() {
      this.$router.push("/profile");
    },
  },
  computed: {
      ...mapGetters(['logged', 'user']), // Importer le getter isConnected du store Vuex
  },
  async mounted() 
  {
    if(this.logged) {
        if(this.user.player) {
          this.player = this.user.player;
          this.progress = "finish";
          this.step = 3;
          return;
        }
        else {
          this.progress = "process";
          this.step = 2;
        }
      return;
    }

    getUser().then((response) => {
      const data = response;
      console.log(response);
      this.refreshUser(response);
      if(response.player) {
        this.player = response.player;
        this.progress = "finish";
        this.step = 3;
        this.goToProfile();
      } else {
        this.progress = "process";
        this.step = 2;
      }
    }).catch((error) => {
      console.error(error);
      this.error = error;
      this.progress = "error";
    })
    this.$router.push({ 
        path: this.$route.path
      });
  },
};
</script>

<style scoped>
h2 {
  text-align: center;
  font-size: 50px;
}
.buttons-container {
  margin-top: 10px;

  button {
    margin: 5px;
  }
}
.button-connect {
  font-size: 20px;
}
</style>

<style scoped>
.section {
  max-width: fit-content;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 50px;
}
</style>