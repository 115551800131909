<template>
  <n-empty size="huge" description="Erreur 404: La page n'existe pas">
    <template #extra>
      <router-link to="/">
        <n-button size="large">
          Retourner en Wilderness
        </n-button>
      </router-link>
    </template>
  </n-empty>
</template>