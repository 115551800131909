<template>
    <n-select
        :options="rolesOptions"
        :default-value="defaultValue"
    ></n-select>
</template>

<script>

export default {
    name: "RoleSelect",
    props: {
        defaultValue: {
            type: String,
            default: ""
        },
        guildDiscord: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            rolesOptions: [{
                label: "Aucune mention",
                value: ""
            }]
        };
    },
    mounted() {
        this.rolesOptions = [{
                label: "Aucune mention",
                value: ""
            }].concat(this.guildDiscord.roles.map(r => ({
            label: "@" + r.name,
            value: r.id
        })));
    }
}
</script>