<template>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.99998 11.1712C1.99998 9.61301 2.86399 8.18991 4.22886 7.49999L18.2289 0.423239C19.3453 -0.141079 20.6547 -0.14108 21.7711 0.423238L35.7711 7.49999C37.136 8.18991 38 9.61301 38 11.1712V28.8288C38 30.387 37.136 31.8101 35.7711 32.5L21.7711 39.5768C20.6547 40.1411 19.3453 40.1411 18.2289 39.5768L4.22886 32.5C2.86399 31.8101 1.99998 30.387 1.99998 28.8288V11.1712Z" fill="white"/>
        <path d="M20.8855 4.95404C20.3274 4.67188 19.6726 4.67188 19.1144 4.95404L7.12168 11.0162C5.76385 11.7025 6.68875 13.7417 7.65959 15.8821C7.89529 16.4017 8.13369 16.9363 8.34279 17.45C8.75461 18.4617 10.8744 19.3139 13.037 20.1925C15.2145 21.0771 17.1978 21.5198 18 23.0708C18.5472 24.1289 18.5042 24.7467 18.4561 25.4358C18.3802 26.5252 18.4561 27.3363 19 28.1888C19.166 28.449 19.4419 28.7426 19.7126 29.0404C20.3158 29.7041 20.8136 30.7207 20.603 31.6012C20.1921 33.3193 19.1191 35.8477 21.9701 34.4977L23.121 33.916C25.2637 32.8329 27.5936 27.805 29.3686 26.1672C29.8 25.7691 30.2116 25.3488 30.5346 24.905C30.8838 24.4253 30.8753 23.7615 30.4895 23.3121C28.5252 21.0236 25.9682 21.8336 23.4974 22.1013C22.0059 22.2629 20.6982 22.4046 20 22.0472C19.6368 21.8613 19.2622 21.6887 18.8957 21.5198C17.2442 20.7587 15.7575 20.0735 16.2178 18.5925C16.7002 17.0407 19.6915 15.6487 20.5035 17.9345C20.9321 19.1409 22.6464 20.2925 22.83 19.078C22.8923 18.6663 22.7547 18.1521 22.6029 17.5848C22.3069 16.4788 21.9568 15.1705 22.928 14.0243C23.6522 13.1697 24.4087 12.719 25.2105 12.2414C26.0356 11.7499 26.9085 11.2299 27.8434 10.2118C28.0251 10.014 28.889 8.99964 27.8434 8.47111L20.8855 4.95404Z" fill="#003366"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <path d="M0 0H40V40H0V0Z" fill="white"/>
        </clipPath>
        </defs>
    </svg>
</template>