<template>
    <div v-if="logged" class="flex">
        <div class="flex responsive" style="width: 400px;">
            <n-card title="">
                <n-menu
                    v-model:expandedKeys="expendedKeys"
                    :options="menuOptions"  
                    :default-expanded-keys="defaultExpandedKeys"
                    @update:expanded-keys="handleUpdateExpandedKeys"
                    @update:value="handleUpdateValue"
                />
            </n-card>
        </div>
        <div class="section">
            <NgAccount v-if="selectedItemKey === 'nationsglory'"/>
            <Account v-else-if="selectedItemKey === ''"/>
            <AddGuild v-else-if="selectedItemKey === 'add'"/>
            <GuildView v-else :id="selectedItemKey" :icon="selectedItem.icon" :name="selectedItem.name" />
        </div>
    </div>
</template>

<script>
import Account from "@/components/profile/Account.vue";
import { mapGetters } from "vuex";
import { DiscordIcon, NationsGlory  , KeyOutline, AddOutline } from '@/assets/icons';
import { h } from 'vue'
import { NIcon, NImage } from 'naive-ui'
import AddGuild from "../components/profile/AddGuild.vue";
import GuildView from "../components/profile/Guild.vue";
import NgAccount from "../components/profile/NationsGlory.vue";
function renderIcon(icon) {
  return () => h(NIcon, null, { default: () => h(icon) })
}

function renderImage(image) {
  return () => h(NImage, { src: image, style: 'width: 32px; height: 32px; border-radius:100%' })
}

export default {
    components: {
        Account,
        DiscordIcon,
        NationsGlory,
        KeyOutline,
        AddOutline,
        AddGuild,
        GuildView,
        NgAccount
    },
    computed: {
        ...mapGetters(['logged', 'user']), // Importer le getter isConnected du store Vuex
        menuOptions() {
            const guilds = this.user.guilds.map(guild => ({
                            key: guild,
                            title: guild.name,
                            icon: guild.icon ? renderImage(`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}?size=32`) : ""
                        })) ?? [];

            guilds.push({
                key: "add",
                title: "Ajouter",
                icon: renderIcon(AddOutline)
            })
            return [
                {
                    key: "account",
                    title: "Compte",
                    icon: renderIcon(KeyOutline)  
                },
                {
                    key: "nationsglory",
                    title: "NationsGlory",
                    icon: renderIcon(NationsGlory),
                    disabled: !this.user.player
                },
                {
                    key: "guilds",
                    title: "Guildes",
                    icon: renderIcon(DiscordIcon),
                    disabled: !this.user.guilds,
                    children: guilds
                }
            ];
        },
        selectedItemKey() {
            // Obtenir la partie clé de l'URL pour déterminer la sélection actuelle
            const routeName = this.$route.name;
            if(routeName === 'profile') {
                return '';
            } else if (routeName === 'profile-nationsglory') {
                return 'nationsglory';
            } else if (routeName === 'profile-guilds') {
                return this.$route.params.id;
            }
            else if (routeName === "profile-add") {
                return 'add';
            } else {
                return '';
            }
        },
        selectedItem() {
            if(this.selectedItemKey === 'nationsglory') {
                return this.user.player;
            } else if (this.selectedItemKey === 'account') {
                return this.user;
            } else if (this.selectedItemKey === 'add') {
                return null;
            } else {
                return this.user.guilds.find(guild => guild.id === this.selectedItemKey);
            }
        }
    },
    data() {
        return {
            defaultExpandedKeys: ["guilds"],
            selectedKeys: []
        };
    },  
    methods: {
        handleUpdateExpandedKeys(value) {
            this.selectedKeys = value;
        },
        handleUpdateValue(value) {
            if(value === 'account') {
                this.$router.push({ path: `/profile` });
            }
            else if (value === 'nationsglory') {
                this.$router.push({ path: `/profile/nationsglory` });
            } else if (value === 'add') {
                this.$router.push({ path: `/profile/add` });
            } else {
                this.$router.push({ path: `/profile/guilds/${value.id}/main` });
            }
        }
    }
}
</script>

<style scoped>
.main-title {
  padding: 0 15vw;
  margin-top: 0;

  text-align: left;
  font-size: 50px;

  word-break: break-word;
}
.section {
    max-width: 700px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
    margin-top: 50px;
  }

  @media screen and (max-width: 600px) {
    .section {
      margin-left: 0;
    }
  }
</style>